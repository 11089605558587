import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  GetRowIdParams,
  ICellRendererParams,
  ISetFilterParams,
} from 'ag-grid-enterprise';
import { AgGridTable } from 'src/components/AgGridTable';
import { SerieData } from 'src/models/hooks/useQuerySeries';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import { Tooltip } from 'react-tooltip';
import { useQuerySerieTags } from 'src/models/hooks/useQuerySerieTags';
import { ButtonRounded } from 'src/components/ButtonRounded';
import { Trash, X } from 'phosphor-react';
import apiSeries from 'src/models/service/apiSeries';
import { queryClient } from 'src/service/queryClient';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Button } from 'src/components/Button';
import { sleep } from 'src/utils/sleep';
import { FailedModal } from 'src/components/Modal/Failed';
import ms from 'ms';

import { FooterContainer, TableSeriesContainer } from './styles';
import { CellLoading } from '../CellLoading';
import { TableSerieTag } from '../Tag';
import { DeleteSerie } from '../DeleteSerie';
import { CellDefault } from '../CellDefault';
import { CellNewSerie } from '../CellNewSerie';
import { ModalContent } from '../DeleteSerie/styles';

type TableSeriesProps = {
  data: SerieData[];
  isLoading: boolean;
  selectedSeries: string[];
  multipleDeletions: boolean;
  setMultipleDeletions: (value: boolean) => void;
  setSelectedSeries: (series: string[]) => void;
  onHandleScroll: (selectedSeries: string[]) => void;
  removeNewSerie: (newSerieId: string) => void;
  updateSelectedTags: (tags: string[]) => void;
  tableRef: React.RefObject<AgGridReact<any>>;
};

const dataLoading: SerieData[] = Array.from({ length: 6 }).map((_, index) => ({
  id: index.toString(),
  created: '2020-01-01',
  last_updated: '2020-01-01',
  frequency: 'monthly',
  name: '',
  status: '',
  tag: '',
  isSelected: false,
}));

export const TableSeries: React.FC<TableSeriesProps> = ({
  tableRef,
  data,
  isLoading,
  multipleDeletions,
  setMultipleDeletions,
  selectedSeries,
  setSelectedSeries,
  onHandleScroll,
  removeNewSerie,
  updateSelectedTags,
}) => {
  const { serieTagsData, serieTagsIsLoading, serieTagsIsError } =
    useQuerySerieTags();

  const [tableRendered, setTableRendered] = useState(false);

  const [failedModalVisible, setFailedModalVisible] = useState(false);
  const [showModalToConfirmDeletion, setShowModalToConfirmDeletion] =
    useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingSerie, setDeletingSerie] = useState(1);

  const { t } = useTranslation();

  const translateFormat = useFormatDateLanguage();

  const colDefs: ColDef[] = [
    {
      field: 'isSelected',
      headerName: ' ',
      pinned: true,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      minWidth: 48,
      maxWidth: 48,
      checkboxSelection: true,
      hide: !multipleDeletions || isLoading,
    },
    {
      field: 'name',
      headerName: t('name'),
      pinned: true,
      cellRenderer: (params: ICellRendererParams<SerieData>) => {
        if (isLoading) {
          return <CellLoading />;
        }
        if (params.data?.status === 'last_updated') {
          return (
            <CellNewSerie
              serieName={params.data.name}
              serieId={params.data.id}
              removeNewSerie={removeNewSerie}
            />
          );
        }
        return (
          <CellDefault
            name={params.data!.name}
            columnName={params.column!.getColId()}
            value={params.data!.name}
          />
        );
      },
    },
    {
      field: 'frequency',
      headerName: t('frequency'),
      valueFormatter: ({ value }) => t(value),
    },
    { field: 'id' },
    {
      field: 'tag',
      cellRenderer: (params: ICellRendererParams) => {
        if (isLoading) {
          return <CellLoading />;
        }
        return <TableSerieTag value={params.value} name={params.data.name} />;
      },
      suppressHeaderFilterButton:
        isLoading || serieTagsIsLoading || serieTagsIsError,
      filter: 'agSetColumnFilter',
      menuTabs: ['filterMenuTab'],

      filterParams: {
        treeList: true,
        values: serieTagsData,
      } as ISetFilterParams,
    },
    {
      field: 'last_updated',
      minWidth: 180,
      maxWidth: 180,
      headerName: t('lastUpdated'),
      valueFormatter: ({ value }) =>
        format(new Date(value), `${translateFormat}, HH:mm`),
    },
    {
      headerName: '',
      field: 'delete',
      pinned: 'right',
      minWidth: 52,
      maxWidth: 52,
      cellRenderer: (param: ICellRendererParams) => (
        <DeleteSerie serieName={param.data.name} serieId={param.data.id} />
      ),
    },
  ];

  const defaultColDef: ColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 140,
      maxWidth: 230,
      autoHeight: true,
      sortable: false,
      suppressHeaderMenuButton: true,
      resizable: false,

      cellRenderer: (params: ICellRendererParams<SerieData>) => {
        if (isLoading) {
          return <CellLoading />;
        }

        const columnName = params.column!.getColId();

        if (
          params.data?.status === 'last_updated' &&
          ['id', 'frequency'].includes(columnName)
        ) {
          return <CellLoading />;
        }

        const value = params.valueFormatted || params.value;

        return (
          <CellDefault
            name={params.data!.name}
            columnName={columnName}
            value={value}
          />
        );
      },
    }),
    [isLoading],
  );

  const getRowId = useCallback(
    (params: GetRowIdParams) => String(params.data.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(data)],
  );

  const handleDeleteAllSeries = async () => {
    try {
      setIsDeleting(true);

      for (let i = 0; i < selectedSeries.length; i++) {
        setDeletingSerie(i + 1);

        await apiSeries.delete(`/series/${selectedSeries[i]}`);

        let { data: serieData } = await apiSeries.get<SerieData>(
          `/series/${selectedSeries[i]}`,
        );

        while (serieData.status !== 'removed') {
          sleep(ms('3 sec'));

          const response = await apiSeries.get<SerieData>(
            `/series/${selectedSeries[i]}`,
          );

          serieData = response.data;
        }
      }

      setSelectedSeries([]);

      queryClient.refetchQueries('series');
      queryClient.refetchQueries('serie-tags');
      queryClient.refetchQueries('series-grouped-by-tag');
      queryClient.removeQueries(['workspace staging area']);
    } catch {
      setFailedModalVisible(true);
    }

    setIsDeleting(false);
    setShowModalToConfirmDeletion(false);
  };

  const handleDeleteSeries = () => {
    const selectedRows =
      tableRef.current?.api?.getSelectedRows()?.map((row) => row.id) ?? [];

    let updatedSelectedSeries: string[] = [];

    for (let i = 0; i < selectedSeries.length; i++) {
      const serie = selectedSeries[i];

      const serieIsShowing = data.some(({ id }) => id === serie);

      if ((serieIsShowing && selectedRows.includes(serie)) || !serieIsShowing) {
        updatedSelectedSeries.push(selectedSeries[i]);
      }
    }

    updatedSelectedSeries = [...updatedSelectedSeries, ...selectedRows];

    if (updatedSelectedSeries?.length) {
      setSelectedSeries(updatedSelectedSeries);
      setShowModalToConfirmDeletion(true);
    }
  };

  const handleCancelMultipleDeletions = () => {
    const allSelectedSeries =
      tableRef.current?.api?.getSelectedRows()?.map((row) => row.id) ?? [];

    allSelectedSeries.forEach((id) => {
      tableRef.current?.api.getRowNode(id)?.setSelected(false);
    });

    setSelectedSeries([]);
    setMultipleDeletions(false);
  };

  useEffect(() => {
    if (tableRef.current?.api) {
      selectedSeries.forEach((id) => {
        tableRef.current?.api.getRowNode(id)?.setSelected(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedSeries), JSON.stringify(data)]);

  const rowData = !isLoading ? data : dataLoading;

  return (
    <TableSeriesContainer isLoading={isLoading} data-cy="container-table">
      <AgGridTable
        tableRef={tableRef}
        rowData={rowData as any}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        maxRemHeight={30}
        suppressCellFocus
        suppressMenuHide
        suppressContextMenu
        enableRangeHandle={false}
        enableRangeSelection={false}
        onFirstDataRendered={() => setTableRendered(true)}
        checkDomLayoutType={tableRendered}
        containerStyle={{
          height: '30rem',
        }}
        onFilterChanged={(param) => {
          if (param.columns.length && param.columns[0].getColId() === 'tag') {
            param.api.getColumnFilterInstance('tag').then((filter) => {
              //@ts-expect-error:ignora
              if (filter?.valueModel?.selectedKeys) {
                const selectedKeys = Array.from(
                  //@ts-expect-error:ignora
                  filter.valueModel.selectedKeys,
                ) as string[];

                updateSelectedTags(selectedKeys);
              }
            });
          }
        }}
        onBodyScrollEnd={(event) => {
          if (event.top !== 0) {
            const selectedRows = tableRef.current?.api.getSelectedRows() ?? [];

            onHandleScroll(selectedRows.map((row) => row.id));
          }
        }}
        suppressScrollOnNewData
        rowSelection="multiple"
        rowMultiSelectWithClick
      />

      <FooterContainer>
        {multipleDeletions ? (
          <>
            <ButtonRounded
              icon={<X />}
              label={t('cancel')}
              onClick={handleCancelMultipleDeletions}
              disabled={false}
            />
            <ButtonRounded
              icon={<Trash />}
              label={t('delete')}
              onClick={handleDeleteSeries}
              disabled={false}
            />
          </>
        ) : (
          <ButtonRounded
            icon={<Trash />}
            label={t('mySeriesMultipleDeletions')}
            onClick={() => setMultipleDeletions(true)}
            disabled={false}
          />
        )}
      </FooterContainer>

      {failedModalVisible && (
        <FailedModal
          errorInfo={{
            title: t('mySeriesDeleteSerieErrorTitle'),
            description: t('mySeriesDeleteSerieErrorDescription', {
              // serie: serieName,
              serie: '',
            }),
          }}
          setVisible={() => setFailedModalVisible(false)}
          visible
        />
      )}

      {showModalToConfirmDeletion && (
        <Modal
          visible
          dataCy="modal-deletion-confirmation"
          style={{ width: '30rem' }}
        >
          <ModalContent>
            <h2>{t('mySeriesConfirmMultipleDeletionsTitle')}</h2>
            <p>{t('mySeriesConfirmMultipleDeletionsDescription')}</p>
          </ModalContent>

          <ModalFooter>
            <Button
              buttonType="naked"
              onClick={() => setShowModalToConfirmDeletion(false)}
              disabled={isDeleting}
              data-testid="button-cancel"
            >
              {t('cancel')}
            </Button>

            <Button
              buttonType="primary"
              onClick={handleDeleteAllSeries}
              loading={isDeleting}
              disabled={isDeleting}
              data-testid="button-confirm"
            >
              {isDeleting
                ? `${t('mySeriesDeleting')} ${deletingSerie}/${
                    selectedSeries.length
                  }`
                : t('yes')}
            </Button>
          </ModalFooter>
        </Modal>
      )}

      <Tooltip
        id="table-serie-tooltip"
        className="customTooltipTheme maxWidthUnsetImportant"
      />
    </TableSeriesContainer>
  );
};
